import httpService from "./httpService";

export const getNotifications = async (body) => {
  return (await httpService.get("/notifications", body).then((response) => {
    try {
      return response.data;    
    } catch (error) {
      const message = error.response.data.message;
      console.error(message);
    }
  } ));
};

export const updateNotifications = async (body) => {
  return (await httpService.put("/notifications", body).then((response) => {
    try {
      return response.data;    
    } catch (error) {
      const message = error.response.data.message;
      console.error(message);
    }
  }));
};

export const removeNotifications = async (body) => {
  return (await httpService.delete("/notifications", { data: body }).then((response) => {
    try {
      return response.data;    
    } catch (error) {
      const message = error.response.data.message;
      console.error(message);
    }
  }));
};