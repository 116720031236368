<template>
  <div
    v-if="notifications.length > 0"
    class="relative"
  >
    <Button
      :badge="1"
      :badgeClass="badgeClass"
      outlined
      class="w-12 h-12 flex items-center justify-center focus:!shadow-none !min-h-fit lg:min-h-[50px] without-border"
      type="button"
      rounded
      aria-haspopup="true"
      icon="uil uil-bell text-neutral-low-dark"
      aria-controls="overlay_notify"
      @click="toggle"
    />
    <TieredMenu
      id="overlay_notify"
      ref="menu_notifications"
      :appendTo="isMobile ? 'body' : 'self'"
      class="overflow-y-auto min-w-[264px]"
      :model="notifications"
      popup
    >
      <template #item="{item}">
        <Notification
          :notification="item"
          @notification-removed="notificationRemoved"
        />
      </template>
    </TieredMenu>
  </div>
</template>

<script setup>
import moment from "moment";
import { useToast } from "primevue/usetoast";
import {
  onBeforeMount,
  ref
} from "vue";

import { useIsMobile } from "@/hooks";
import { getNotifications }  from "@/services/notifications";

import Notification from "./components/Notification.vue";

const toast = useToast();

const {isMobile} = useIsMobile();

const menu_notifications = ref();
const notifications = ref([]);
const summary = ref();
const badgeClass = ref();

const toggle = (event) => {
  menu_notifications.value.toggle(event);
};

const handleGetNotifications = async () => {
  try {
    await getNotifications()
      .then((res) => {
        const response = res.data;
        if (res.data) {
          notifications.value = response.notifications;
          notifications.value.map((item) =>{
            item.updated_at = moment(item.updated_at).format("DD/MM/YYYY");
          });
          summary.value = res.data.summary;
          badge();
        }
    
        return;
      });
      
  } catch (error) {
    console.error(error);
  }
};

const badge = () => {
  badgeClass.value =
    summary.value.unread === 0 ? "custom-badge !text-transparent opacity-0" : "custom-badge !text-transparent opacity-100";
};

const notificationRemoved = () => {
  toast.add({
    severity: "success",
    summary: "Notificação removida com sucesso!",
    life: 3000,
  });
  handleGetNotifications();
};

onBeforeMount(() => {
  handleGetNotifications();
});
</script>

<style lang="scss" scoped>
@import "@/styles/themes/tailwind/tailwind-light/_variables.scss";

:deep(#overlay_notify) {
  top: 38px !important;
  right: 0 !important;
  left: auto !important;

  width: 250px;
  max-height: 300px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

:deep(.p-button) {
  position: relative;
  overflow: visible;

  .p-badge.custom-badge {
    position: absolute;
    top: -4px;
    right: -4px;
    order: 1;
    width: 10px !important;
    vertical-align: middle;
    color: #fff;
    background-color: #60C46F;
    font-weight: normal;
  }

  .p-button-icon {
    position: absolute;
  }
}
.without-border {
  border: none !important;
}
</style>
