<template>
  <div
    class="py-3 px-4 cursor-pointer border-b border-neutral-high-light relative"
    :class="data.read_at == null ? 'new' : ''"
    @click="handleViewedNotification"
  >
    <p class="flex flex-col justify-between">
      <strong class="pr-2">{{ data.cta }}</strong>
      <small class="datetime">{{ data.updated_at }}</small>
    </p>
    <span :class="data.icon" />
    <p class="mt-2">
      {{ data.message }}
    </p>
    <Button
      icon="uil-trash !text-neutral-low-dark transition-colors duration-300"
      class="!absolute top-3 right-3 !w-[3rem] !border-neutral-low-dark"
      outlined 
      rounded 
      title="remover notificação"
      aria-label="remover notificação"
      @click="handleRemoveNotification"
    />
  </div>
</template>

<script setup>
import {
  defineEmits,
  onBeforeMount,
  ref
} from "vue";

import { removeNotifications,updateNotifications } from "@/services/notifications";

const data = ref({});

const props = defineProps({
  notification: {
    type: Object,
    default: null
  }
});

const emit = defineEmits(["notification-removed"]);

const handleViewedNotification = async () => {
  await  updateNotifications({
    id: props.notification.id
  });
      
  document.location.href = props.notification.url;
};

const handleRemoveNotification = async (e) => {
  e.stopPropagation();
  await removeNotifications({
    "id": props.notification.id
  }).then(()=>{
    emit("notification-removed");
  });
};

onBeforeMount(()=> {
  data.value = props.notification;
});

</script>

<style lang="scss" scoped>
@import "../../../styles/themes/tailwind/tailwind-light/variables";

.new {
  background-color: $primaryLightColor;
  border-bottom: 1px solid $primaryMediumColor;

  &:hover {
    background-color: $primaryMediumColor;
    border-color: $primaryDarkerColor;
  }

  &:active {
    background-color: var(--twc-primary-default);
    color: $whiteColor;
  }
}

</style>